import { WorkspaceType } from 'types/feature/workspace/workspace';
import { capitalizeFirstLetter } from 'utils/common/capitalizeFirstLetter';

const WorkspaceTypeBadge = ({ type }: { type: WorkspaceType }) => {
  return (
    <div className='h-[24px] px-[6px] flex justify-center items-center rounded bg-transparent border border-[#EAEAEA]'>
      <span className='text-[12px] font-medium leading-3 text-[#BFBFBF]'>{capitalizeFirstLetter(type)}</span>
    </div>
  );
};
export default WorkspaceTypeBadge;
