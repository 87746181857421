import { ICON } from 'constants/icons';
import { PAGINATION_LIMIT } from 'data/common';
import ReactPaginate from 'react-paginate';

type PaginationProps = {
  totalElements: number;
  currentPage: number;
  onChangePage: (page: number) => void;
};

// 공통으로 사용하는 pagination component
// 데이터는 최상단에서 관리해주세요
const Pagination = ({ currentPage, totalElements, onChangePage }: PaginationProps) => {
  const handleChangePage = (selectedItem: { selected: number }) => {
    onChangePage(selectedItem.selected);
  };

  return (
    <ReactPaginate
      className='flex items-center justify-center w-full pt-6 cursor-pointer'
      previousLabel={<img src={ICON.ARROW_PREV_DISABLE} alt='prev' />}
      nextLabel={<img src={ICON.ARROW_NEXT_ABLE} alt='next able' />}
      pageCount={Math.ceil(totalElements / PAGINATION_LIMIT)}
      forcePage={currentPage}
      onPageChange={handleChangePage}
      pageClassName='w-10 h-10 flex justify-center items-center text-body-1 text-gray-99 text-[14px]'
      activeClassName='w-10 h-10 flex justify-center items-center text-gray-44 font-bold text-[14px]'
    />
  );
};

export default Pagination;
