import SaveGuideDescription from 'components/common/guide/SaveGuideDescription';
import AlertModal from 'components/common/modal/alert/AlertModal';
import TableNoContent from 'components/common/no-content/TableNoContent';
import CommonTableHeader from 'components/common/table/header/CommonTableHeader';
import { SCHEDULE_TABLE_HEADER } from 'data/table/screensaver/scheduleListTableHeader';
import useScreensaver from 'hooks/feature/screensaver/useScreensaver';
import { useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import useFilterStore from 'stores/filter';
import { CONTENTS_BG } from 'styles/background';
import { TABLE_PADDING } from 'styles/padding';
import { ScreenType } from 'types/feature/content/contents';
import { GetScreensaver } from 'types/feature/screensaver/getScreensaver';
import { handle400Error } from 'utils/error/handle400Error';
import ScheduleTableRow from './row/ScheduleTableRow';
import { CommonOptionType } from 'components/common/select/CommonSelectBox';
import { handleSortOrderDesc } from 'utils/sort-order/handleSortOrderDesc';

type Props = {
  screensavers: GetScreensaver[];
  refetchScreensaver: (pointId: string, screenType: ScreenType) => Promise<void>;
  selectedPoint: CommonOptionType | undefined;
};

const ScheduleTable = ({ screensavers, refetchScreensaver, selectedPoint }: Props) => {
  const { updateScreensaver } = useScreensaver();

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const filterStore = useFilterStore();

  // 스크린세이버 drag 시
  const handleDragScreensaver = async (dropResult: DropResult) => {
    try {
      const sourceIndex = dropResult.source.index;
      const currentItemId = screensavers[sourceIndex].id;

      const sortOrder = handleSortOrderDesc(dropResult, screensavers);

      if (sortOrder) {
        await updateListTable(currentItemId, sortOrder);
      }
    } catch (error) {
      handle400Error(error, handleAlert);
    }
  };

  // 스크린세이버 업데이트
  const updateListTable = async (currentItemId: string, sortOrder: number) => {
    try {
      await updateScreensaver(currentItemId, { sortOrder });
      await refetchScreensaver(selectedPoint?.value || '', filterStore.scheduleScreenType);
    } catch (error) {
      handle400Error(error, handleAlert);
    }
  };

  const handleAlert = () => {
    setAlertMessage('광고 스케줄울 수정할 수 없습니다.');
    setOpenAlert(true);
  };

  return (
    <>
      <div className={`${CONTENTS_BG} ${TABLE_PADDING} flex flex-col gap-10`}>
        <SaveGuideDescription message='모든 변동사항은 익일 반영됩니다.' />

        <table>
          <CommonTableHeader tableHead={SCHEDULE_TABLE_HEADER} />

          <DragDropContext onDragEnd={handleDragScreensaver}>
            <Droppable droppableId='table'>
              {provided => {
                return (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {screensavers.length > 0 ? (
                      screensavers
                        ?.sort((a, b) => b.sortOrder - a.sortOrder)
                        .map((row, index) => {
                          return (
                            <Draggable draggableId={`drag-${index}`} index={index} key={`drag-${index}`}>
                              {draggableProvided => (
                                <ScheduleTableRow
                                  provided={draggableProvided}
                                  row={row}
                                  index={index}
                                  refetchScreensaver={refetchScreensaver}
                                  selectedPoint={selectedPoint}
                                />
                              )}
                            </Draggable>
                          );
                        })
                    ) : (
                      <TableNoContent message='등록된 광고 스케줄이 없습니다.' colSpan={8} />
                    )}

                    {provided.placeholder}
                  </tbody>
                );
              }}
            </Droppable>
          </DragDropContext>
        </table>
      </div>

      {openAlert && <AlertModal message={alertMessage} closeModal={() => setOpenAlert(false)} />}
    </>
  );
};
export default ScheduleTable;
