import { useApi } from 'api/useApi';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { Poi } from 'types/feature/poi/poi';

const usePoi = () => {
  const { api } = useApi();

  const getPoiContents = async (pointId: string, poiId: string, languageCode: string) => {
    try {
      const result = await api.get<ApiResponse<Poi[]>>(Endpoint.poiContents, {
        params: {
          pointId,
          poiId,
          languageCode,
        },
      });

      if (result.data) {
        return result.data;
      }
    } catch {
      console.error('지원하지 않는 지도 입니다. ');
      //   return { result: 'ERROR' };
    }
  };

  return { getPoiContents };
};

export default usePoi;
