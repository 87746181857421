import KeywordItem from './KeywordItem';

const KeywordsViewer = ({ keywords }: { keywords: string[] }) => {
  return (
    <div className='flex w-full '>
      <div className='flex gap-[10px]'>
        {keywords.map((keyword: string, index: number) => (
          <KeywordItem keyword={keyword} type='item' key={index} />
        ))}
      </div>
    </div>
  );
};
export default KeywordsViewer;
