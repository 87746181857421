import { throwErrorIfFalsy } from 'utils/error/throwErrorr';

export const returnSortOrder = (prev: number, next: number) => {
  if (prev === undefined && next === undefined) {
    throw new Error();
  }

  // prev나 next가 양수가 아닌 경우
  if (prev <= 0 || next <= 0) {
    throw new Error();
  }

  // prev가 next보다 크거나 같은 경우
  if (next >= prev) {
    throw new Error();
  }

  const order = Math.floor((next - prev) / 2);
  throwErrorIfFalsy(order);

  return prev + order;
};
