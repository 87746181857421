import { ChangeEvent, useState } from 'react';
import { InputSize, InputText } from './Input.type';

type Props = {
  placeholder?: string;
  type?: 'text' | 'password' | 'number' | 'time';
  readOnly?: boolean;
  defaultValue?: string;
  value?: string | number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  autoComplete?: 'username' | 'current-password';
  id?: string;
  name?: string;
  textStyle?: string;
  disabled?: boolean;
  inputStyle?: string;
  size?: 'default' | 128 | 200 | 224 | 360 | 384;
};

const CommonInput = ({
  placeholder,
  type,
  readOnly,
  defaultValue,
  value,
  onChange,
  autoComplete,
  id,
  name,
  textStyle = InputText.default,
  disabled,
  inputStyle,
  size = 'default',
}: Props) => {
  const [onFocus, setOnFocus] = useState(false);

  return (
    <div
      onFocus={() => setOnFocus(true)}
      onBlur={() => setOnFocus(false)}
      className={`flex items-center justify-center gap-2 border h-9 bg-white ${inputStyle} ${InputSize[size]} ${
        onFocus ? 'border-state-blue' : 'border-gray-99'
      }`}
    >
      <input
        className={`
        ${readOnly && 'border-0'}
        ${textStyle}
      text-gray-44
      placeholder:text-gray-cc 
        placeholder:text-body1
        placeholder:font-normal
        focus:outline-none
        border-none w-full h-full px-3
        `}
        disabled={disabled}
        placeholder={placeholder}
        autoComplete={autoComplete}
        id={id}
        onChange={onChange}
        defaultValue={defaultValue}
        readOnly={readOnly}
        type={type ?? 'text'}
        value={value}
        name={name}
      />
    </div>
  );
};
export default CommonInput;
