import { TextType } from 'components/common/title/Title';
import usePoi from 'hooks/feature/poi/usePoi';
import { useEffect, useState } from 'react';
import { CONTENTS_BG } from 'styles/background';
import { FLEX_CENTER } from 'styles/flex';
import { Poi } from 'types/feature/poi/poi';
import { Point } from 'types/feature/workspace/workspace';
import PoiContentPreview from './fields/PoiContentPreview';
import FormControl from 'components/common/form-control/FormControl';
import { OptionType } from 'components/common/select/SelectBox';
import { Floor } from 'types/feature/floor/floor';
import CommonSelectBox from 'components/common/select/CommonSelectBox';

type Props = {
  point: Point;
  clickedPoiId?: string;
  selectedLang: string;
  selectedFloor: Floor;
};

const TenantEditSpace = ({ point, clickedPoiId, selectedLang, selectedFloor }: Props) => {
  // state
  // const [isLoading, setLoading] = useState<boolean>(false); TODO
  const [contents, setContents] = useState<Poi[]>([]);
  const [selectPoiId, setSelectPoiId] = useState<OptionType>({ label: '', value: '' });
  const [filteredContent, setFilteredContent] = useState<Poi | null>(null);
  const [options, setOptions] = useState<OptionType[]>([]);
  const [isError, setError] = useState<boolean>(false);
  // hook
  const poiManager = usePoi();

  const getContents = async () => {
    if (!clickedPoiId) {
      setFilteredContent(null);
      setOptions([]);
      setContents([]);
      setSelectPoiId({ label: '', value: '매장을 선택해주세요.' });
      return;
    }

    try {
      // setLoading(true);
      setError(false);
      const response = await poiManager.getPoiContents(point.id, clickedPoiId, selectedLang.toUpperCase());
      // console.log('response', response);
      if (response) {
        // console.log('selectedFloor', selectedFloor);
        // console.log('response', response);
        setContents(response);

        const opt = response?.map(content => ({
          label: content.name,
          value: content.poiId,
        }));

        // console.log('opt', opt);

        if (opt.length === 1) {
          setOptions(opt);
          setSelectPoiId(opt[0]);
        } else if (opt.length > 1) {
          setOptions([{ label: '매장을 선택해주세요.', value: '' }, ...opt]);
          setSelectPoiId({ label: '매장을 선택해주세요.', value: '' });
        } else {
          setOptions([]);
          setSelectPoiId({ label: '', value: '' });
        }
      } else {
        setError(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      // setLoading(false);
    }
  };

  const returnTenantEditableField = () => {
    // 지도에서 선택하는 경우 poiId 가 없다면
    if (!contents) {
      return (
        <div className={`${FLEX_CENTER.row} h-[460px] text-gray-cc ${TextType.h4}`}>지도에서 poi를 선택해주세요.</div>
      );
    }

    if (isError) {
      return (
        <div className={`${FLEX_CENTER.row} h-[460px] text-gray-cc ${TextType.h4}`}>지원하지 않는 지도 입니다. </div>
      );
    }

    if (!filteredContent) {
      return <div className={`${FLEX_CENTER.row} h-[460px] text-gray-cc ${TextType.h4}`}>매장을 선택해주세요.</div>;
    }

    return (
      <PoiContentPreview
        poiContent={filteredContent}
        floor={selectedFloor.name[selectedLang.toLocaleLowerCase()]}
        building={point.name[selectedLang.toLocaleLowerCase()]}
      />
    );
  };

  const onChangeSelect = (option: OptionType) => {
    setSelectPoiId(option);
  };

  useEffect(() => {
    const content = contents?.filter(content => content.poiId === selectPoiId?.value);
    if (content) {
      setFilteredContent(content[0]);
    } else {
      setFilteredContent(null);
    }
  }, [contents, selectPoiId]);

  useEffect(() => {
    setContents([]);
    getContents();
  }, [point.id, clickedPoiId, selectedLang, selectedFloor]);

  return (
    <div className={`h-[698px] ${CONTENTS_BG} w-[480px]`} style={{ padding: '24px 40px 40px 40px' }}>
      {/* 매장 */}
      {options.length > 0 && (
        <div className={`pt-[24px] pb-[24px]`}>
          <FormControl
            name='매장'
            labelSize='none'
            control={
              <CommonSelectBox width='full' options={options} onChangeSelect={onChangeSelect} selected={selectPoiId} />
            }
          />
        </div>
      )}
      <div className='flex flex-col w-full'>{returnTenantEditableField()}</div>
    </div>
  );
};

export default TenantEditSpace;

// const poiContentStore = usePoiContentStore();

// const scrollRef = useRef<HTMLDivElement>(null);

// const returnTenantEditableField = () => {
//   // 지도에서 선택하는 경우 poiId 가 없다면
//   if (!poiContentStore.currentContentId) {
//     return (
//       <div className={`${FLEX_CENTER.row} h-[460px] text-gray-cc ${TextType.h4}`}>지도에서 poi를 선택해주세요.</div>
//     );
//   }

//   if (!tenant) {
//     return <> </>;
//   }

//   return (
//     <TenantEditableFields
//       tenant={tenant}
//       languageList={languageList}
//       mainLanguage={mainLanguage}
//       fetchTenant={fetchTenant}
//       pointName={point.name}
//       pointId={point.id}
//       clickedPoiId={clickedPoiId}
//     />
//   );
// };
