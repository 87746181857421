/* eslint-disable react-hooks/exhaustive-deps */
import useTenant from 'hooks/feature/tenant/useTenant';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import useMapStore from 'stores/map';
import usePoiContentStore from 'stores/poiContent';
import { Floor } from 'types/feature/floor/floor';
import { PoiWithTenants } from 'types/feature/tenant/tenant';
import { Point, Workspace } from 'types/feature/workspace/workspace';
import { showPoiLocation } from 'utils/map/control/location';
import MapDraw from 'utils/map/mapDraw';

type Props = {
  workspace: Workspace;
  selectedPoint: Point;
  selectedFloor: Floor;
  setClickedPoiId: Dispatch<SetStateAction<string>>;
};

const TenantMapContainer = ({ workspace, selectedPoint, selectedFloor, setClickedPoiId }: Props) => {
  const mapStore = useMapStore();
  const mapRef = useRef<HTMLDivElement>(null);

  const [poisWithTenants, setPoisWithTenants] = useState<PoiWithTenants[]>([]);

  const poiContentStore = usePoiContentStore();

  const { getPoisWithTenants } = useTenant();

  // 지도 생성
  const appendMap = async () => {
    if (mapRef.current) {
      await new MapDraw().showMap(mapRef.current, selectedFloor.id);

      mapStore.setMapLoading(false);
    }
  };

  const getMapDataAndAppendMap = async () => {
    try {
      if (workspace) {
        mapStore.setMapLoading(true);

        const mapData = await MapDraw.getInstance().getMapData({
          clientId: workspace.map.temp1,
          clientSecret: workspace.map.temp2,
          version: workspace.map.version,
          serverType: 'SERVER_STUDIO4',
        });

        if (mapData) {
          MapDraw.mapData = mapData;

          await appendMap();
        }
      }
    } catch {
      console.error('map draw error!');
    }
  };

  const fetchPoisWithTenants = async () => {
    const result = await getPoisWithTenants(selectedPoint.id);
    setPoisWithTenants(result);
  };

  const handlePoiClick = async (poi: any) => {
    if (poi.detail.length > 0) {
      // click poi
      const clickPoi = poi.detail[0];
      setClickedPoiId(clickPoi.id);

      if (clickPoi) {
        // TODO: Poi 별 Tenant 목록
        const findPoi = poisWithTenants.find(poi => poi.poiId === clickPoi.id);
        if (findPoi && findPoi.tenants.length > 0) {
          setClickedPoiId(findPoi.poiId);

          // poiContent 가 없으면 마커를 그리지 않는다.
          await showPoiLocation(clickPoi.floorId, clickPoi.position);
          poiContentStore.setCurrentContentId(findPoi.tenants[0].content.id);
        }
      }
    }
  };

  useEffect(() => {
    if (mapStore.loadMapScript) {
      getMapDataAndAppendMap();
    }

    return () => {
      if (mapRef.current) {
        MapDraw.cleanup();
      }
    };
  }, [workspace, mapStore.loadMapScript]);

  useEffect(() => {
    fetchPoisWithTenants();
  }, [selectedPoint]);

  useEffect(() => {
    mapRef.current?.addEventListener('poi-click', handlePoiClick);

    return () => {
      mapRef.current?.removeEventListener('poi-click', handlePoiClick);
    };
  }, [poisWithTenants]);

  return (
    <div className='border h-[618px] overflow-hidden relative w-full'>
      <div className='w-full h-full' ref={mapRef} />
    </div>
  );
};

export default TenantMapContainer;
