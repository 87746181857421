import Input from 'components/common/input/Input';
import useLanguage from 'hooks/feature/language/useLanguage';
import { ChangeEvent, Fragment, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LangCode, LangNameKo } from 'types/feature/multi-lang/language';
import { Point } from 'types/feature/workspace/workspace';

type Props = {
  point: Point;
  handlePoint: (point: Point) => void;
};

const WORD_LIMIT = 15;

const AltText = ({ point, handlePoint }: Props) => {
  const { getLanguages } = useLanguage();
  const { workspaceId } = useParams();

  const [langs, setLangs] = useState<{ code: LangCode; title: LangNameKo }[]>([]);

  const fetchLangs = useCallback(async () => {
    const langList = await getLanguages();
    if (langList) {
      const languages = langList.map(lang => {
        return {
          code: lang.code,
          title: LangNameKo[lang.code],
        };
      });

      setLangs(languages);
    }
  }, [workspaceId]);

  useEffect(() => {
    if (workspaceId) {
      fetchLangs();
    }
  }, [workspaceId]);

  const handleChangeDescription = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    if (value.length > WORD_LIMIT) {
      return;
    }

    handlePoint({
      ...point,
      operation: {
        ...point.operation,
        hoursText: {
          ...point.operation.hoursText,
          [name.toLowerCase()]: value,
        },
      },
    });
  };

  return (
    <div className='flex flex-col justify-center w-full gap-1'>
      {langs.map(language => {
        return (
          <Fragment key={language.code}>
            <div className='flex items-center gap-2 md:w-[100%] lg:w-[80%]'>
              {/* 언어 코드 */}
              <div
                className={`flex items-center justify-center bg-[#999] text-white w-[24px] h-[16px] text-[11px] leading-4`}
              >
                {language.code}
              </div>

              {/* 입력창 */}
              <Input
                useWordLimit
                limit={WORD_LIMIT}
                onChange={handleChangeDescription}
                placeholder={`${language.title} 운영시간을 입력해주세요`}
                size='default'
                value={point.operation.hoursText[language.code.toLowerCase()]}
                name={language.code}
              />
            </div>
          </Fragment>
        );
      })}
      <div className='text-h5 text-gray-99'>{`최대 ${WORD_LIMIT}자까지 입력 가능합니다.`}</div>
    </div>
  );
};

export default AltText;
