import { ScreenType } from 'types/feature/content/contents';
import { create } from 'zustand';

type Props = {
  // TODO: 제거
  // 스케쥴 관리의 스크린 타입 필터
  scheduleScreenType: ScreenType;
  setScheduleScreenType: (screenType: ScreenType) => void;
};

const useFilterStore = create<Props>((set, get) => ({
  scheduleScreenType: 'VERTICAL',
  setScheduleScreenType(screenType: ScreenType) {
    set({ scheduleScreenType: screenType });
  },
}));

export default useFilterStore;
