import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import FormControl from 'components/common/form-control/FormControl';
import CommonInput from 'components/common/input/CommonInput';
import AlertModal from 'components/common/modal/alert/AlertModal';
import ConfirmModal from 'components/common/modal/confirm/ConfirmModal';
import CommonSelectBox, { CommonOptionType } from 'components/common/select/CommonSelectBox';
import Title, { TextType } from 'components/common/title/Title';
import useModal from 'hooks/common/useModal';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NaviGationSpeedOption, NaviSpeedOptionKo } from 'types/feature/workspace/workspace';

const NaviSetting = () => {
  const [naviSpeedOption, setNaviSpeedOption] = useState<CommonOptionType>();
  const [speedRate, setSpeedRate] = useState<string>('35');
  const [lineWidth, setLineWidth] = useState<string>('13');

  const { openModalPopup, openConfirm, openAlert, closeModalPopup } = useModal();
  const { patchWorkspaceNaviSetting, getWorkspace } = useWorkspace();
  const { workspaceId } = useParams();

  /**
   * 모의보행 속도 옵션을 변경한다.
   */
  const onChangeNaviSpeedOption = (option: CommonOptionType) => {
    setNaviSpeedOption(option);
  };

  /**
   * 모의보행 속도를 변경한다.
   */
  const onChangeSpeedRate = (e: ChangeEvent<HTMLInputElement>) => {
    const speed = e.target.value;
    setSpeedRate(speed);
  };

  /**
   * 모의보행 경로 안내선 굵기를 변경한다.
   */
  const onChangeLineWidth = (e: ChangeEvent<HTMLInputElement>) => {
    const width = e.target.value;
    setLineWidth(width);
  };

  /**
   * 워크스페이스를 조회한다.
   */
  const fetchWorkspace = async () => {
    if (!workspaceId) return;

    const workspace = await getWorkspace(workspaceId);
    if (workspace) {
      setNaviSpeedOption({
        label: NaviSpeedOptionKo[workspace.navigationSpeedOption],
        value: workspace.navigationSpeedOption,
      });
      setSpeedRate(String(workspace.navigationSpeed));
      setLineWidth(String(workspace.lineThickness));
    }
  };

  /**
   * 저장한다.
   */
  const onClickSave = async () => {
    try {
      if (workspaceId) {
        await patchWorkspaceNaviSetting(workspaceId, {
          lineThickness: Number(lineWidth) || 0,
          navigationSpeed: Number(speedRate) || 0,
          navigationSpeedOption: (naviSpeedOption?.value as NaviGationSpeedOption) || 'LINEAR',
        });

        await fetchWorkspace();
        closeModalPopup('CONFIRM');
      }
    } catch (error) {
      if (error instanceof AxiosError && error.status === 400) {
        closeModalPopup('CONFIRM');
        openModalPopup('ALERT');
      }
    }
  };

  useEffect(() => {
    if (workspaceId) {
      fetchWorkspace();
    }
  }, [workspaceId]);

  return (
    <section className='flex flex-col gap-5'>
      <Title text='모의보행 설정' textType={TextType.h2} />
      <div className='flex flex-col gap-6 p-10 bg-white border border-gray-ea'>
        {/* 속도 관련 */}
        <div className='grid grid-cols-2'>
          <FormControl
            name='모의보행 속도'
            control={<CommonInput size={200} placeholder='35' value={speedRate || ''} onChange={onChangeSpeedRate} />}
          />
          <FormControl
            name='모의보행 속도 옵션'
            control={
              <CommonSelectBox
                width={200}
                options={naviSpeedOptions}
                selected={naviSpeedOption}
                onChangeSelect={onChangeNaviSpeedOption}
              />
            }
          />
        </div>

        {/* 경로 선 굵기 */}
        <FormControl
          name='경로 안내선 굵기'
          control={<CommonInput size={200} placeholder='13' value={lineWidth || ''} onChange={onChangeLineWidth} />}
        />

        <div className='flex justify-end'>
          <Button text='저장' color={ButtonColor.point} size={80} onClick={() => openModalPopup('CONFIRM')} />
        </div>
      </div>

      {openConfirm && (
        <ConfirmModal
          message='저장 하시겠습니까?'
          onClickCancel={() => closeModalPopup('CONFIRM')}
          onClickOk={onClickSave}
        />
      )}
      {openAlert && <AlertModal message='오류가 발생했습니다.' closeModal={() => closeModalPopup('ALERT')} />}
    </section>
  );
};
export default NaviSetting;

/**
 * 모의보행 속도 옵션들
 */
const naviSpeedOptions: {
  label: NaviSpeedOptionKo;
  value: NaviGationSpeedOption;
}[] = [
  {
    label: NaviSpeedOptionKo.LINEAR,
    value: 'LINEAR',
  },
  {
    label: NaviSpeedOptionKo.EASE_IN,
    value: 'EASE_IN',
  },
];
