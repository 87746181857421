import React from 'react';

type Props = {
  desc: string;
};

const TenantDescription = ({ desc }: Props) => {
  return (
    <div className='flex items-center p-[20px] self-stretch bg-bg-f9'>
      <div className='text-gray-44 text-center text-[14px] font-normal'>{desc.replaceAll('\\n', '\n')}</div>
    </div>
  );
};
export default TenantDescription;
