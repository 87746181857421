import { Point } from 'types/feature/workspace/workspace';

type Props = {
  points: Point[];
  handleClickPoint: (point: Point) => void;
  selectedPoint: Point;
};

const PointsTab = ({ points, handleClickPoint, selectedPoint }: Props) => {
  return (
    <div className='flex'>
      {points.map(point => (
        <button
          onClick={handleClickPoint.bind(this, point)}
          key={point.id}
          className={`${
            selectedPoint?.id === point.id ? 'text-gray-44 border-b-2 border-gray-22' : 'text-gray-99 border-none'
          }
          min-w-[120px] px-6 flex items-center justify-center text-center h-[60px] font-medium text-lg
          `}
        >
          {point.name.ko}
        </button>
      ))}
    </div>
  );
};
export default PointsTab;
