import { DropResult } from 'react-beautiful-dnd';
import { returnSortOrder } from 'utils/sort-order/returnSortOrder';
import { throwErrorIfFalsy, throwErrorIfNegative } from 'utils/error/throwErrorr';

export const handleSortOrderDesc = (dropResult: DropResult, list: any) => {
  // 이상한 곳에 놓았을 경우
  if (!dropResult.destination) return;

  const destinationIndex = dropResult.destination?.index; // 도착한 곳
  const sourceIndex = dropResult.source.index; // 내가 원래 위치한곳

  // sortOrder 감소
  if (sourceIndex < destinationIndex) {
    return decreaseOrder(dropResult, list);
  }

  // sortOrder 증가
  return increaseOrder(dropResult, list);
};

const increaseOrder = (dropResult: DropResult, list: any) => {
  if (!dropResult.destination) return;

  //  아이템을 맨 처음 옮겼을 경우
  if (!list[dropResult.destination.index - 1]) {
    return list[dropResult.destination.index].sortOrder + 1000;
  }

  const prevSortOrder = list[dropResult.destination.index - 1].sortOrder;
  const nextSortOrder = list[dropResult.destination.index].sortOrder;

  const sortOrder = returnSortOrder(prevSortOrder, nextSortOrder);
  return sortOrder;
};

const decreaseOrder = (dropResult: DropResult, list: any) => {
  if (!dropResult.destination) return;
  // 아이템을 맨 마지막으로 옮겼을 경우
  if (!list[dropResult.destination.index + 1]) {
    const sortOrder = list[dropResult.destination.index].sortOrder / 2;
    throwErrorIfFalsy(sortOrder);
    throwErrorIfNegative(sortOrder);
    return sortOrder;
  }

  const nextSortOrder = list[dropResult.destination.index + 1].sortOrder;
  const prevSortOrder = list[dropResult.destination.index].sortOrder;

  const sortOrder = returnSortOrder(prevSortOrder, nextSortOrder);
  return sortOrder;
};
