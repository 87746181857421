import React, { useEffect } from 'react';
import { Poi } from 'types/feature/poi/poi';
import TenantDetailImage from './image/TenantDetailImage';
import TenantPopupTitle from './title/TenantPopupTitle';
import TenantDescription from './description/TenantDescription';
import TenantCustomField from './custom-field/TenantCustomField';
import KeywordsViewer from './keywords/KeywordsViewer';

type Props = {
  poiContent: Poi;
  floor: string;
  building: string;
};

const Badge = ({ popupType }: { popupType: string }) => {
  return (
    <div
      className='flex h-[24px] px-[10px] justify-center items-center rounded-[2px] bg-state-gray_bg'
      style={{ position: 'absolute', top: '10px', right: '10px' }}
    >
      <span className='overflow-hidden text-gray-77 text-ellipsis text-[14px]'>{popupType}</span>
    </div>
  );
};
const PoiContentPreview = ({ poiContent, floor, building }: Props) => {
  return (
    <>
      {
        /* 기본형 혹은 축약형 팝업 */
        poiContent.popupType === 'SMALL' ? (
          <div className='flex flex-col items-center bg-white border' style={{ height: '200px', position: 'relative' }}>
            <Badge popupType={'축약형'} />
            {/* 가로형 제목 */}
            <TenantPopupTitle name={poiContent.name} floor={floor} building={building} />
          </div>
        ) : (
          <div className='flex flex-col items-center bg-white border' style={{ height: '100%', position: 'relative' }}>
            <Badge popupType={'기본형'} />

            {/* 가로형 제목 */}
            <TenantPopupTitle name={poiContent.name} floor={floor} building={building} />
            {/* 이미지  */}
            <TenantDetailImage mainLogoUri={poiContent.subLogoUri} />

            <div className='flex flex-col items-center w-full'>
              <div
                className='h-full w-full flex flex-col overflow-y-auto mt-[20px] mb-[20px]'
                style={{ padding: '0 20px 0px 20px', height: '155px' }}
              >
                {/*  상세 정보 */}
                {poiContent?.description && <TenantDescription desc={poiContent.description} />}
                {/* 커스텀 필드 */}
                <TenantCustomField customFields={poiContent.customField} />
                {/* 커스텀 필드 SNS  */}
                {/* <TenantSnsField customFields={poiContent.customField} /> */}
              </div>
              <div className='w-full mb-[20px]' style={{ padding: '0 20px 0px 20px' }}>
                {/* 키워드 */}
                {poiContent.keywords.length > 0 && <KeywordsViewer keywords={poiContent.keywords} />}
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};

export default PoiContentPreview;
