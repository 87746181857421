import { useApi } from 'api/useApi';
import { PAGINATION_LIMIT } from 'data/common';
import { Endpoint } from 'data/endPoint';
import { useParams } from 'react-router-dom';
import { ApiResponse } from 'types/api/response';
import { Content, ContentsByPage, ScreenType } from 'types/feature/content/contents';
import { PostContent } from 'types/feature/content/postContent';

const useContents = () => {
  const { api } = useApi();
  const { workspaceId } = useParams();

  // 목록 조회
  const getContents = async (currentPage: number, title: string, screenType: ScreenType) => {
    if (!workspaceId) return;

    const result = await api.get<ApiResponse<ContentsByPage>>(Endpoint.contents, {
      params: {
        workspaceId,
        page: currentPage,
        size: PAGINATION_LIMIT,
        title,
        screenType,
      },
    });

    if (result) {
      return result.data;
    }
  };

  // 조회
  const getContent = async (contentId: string) => {
    const result = await api.get<ApiResponse<Content>>(Endpoint.content.replace(':contentId', contentId));
    if (result) {
      return result.data;
    }
  };

  // 콘텐츠 등록
  const uploadContent = async (form: PostContent) => {
    const result = await api.post<ApiResponse<{ title: string }>>(Endpoint.contents, form);

    if (result) {
      return result;
    }
  };

  // 삭제
  const deleteContent = async (contentId: string) => {
    await api.delete<ApiResponse<{ title: string }>>(Endpoint.content.replace(':contentId', contentId));
  };

  // 수정
  const updateContent = async (contentId: string, updateTitle: string) => {
    await api.put<ApiResponse<any>>(Endpoint.content.replace(':contentId', contentId), {
      title: updateTitle,
    });
  };

  // 페이지네이션 없이 Contents 가져오는 함수 (스크린세이버 스케줄 추가에서 사용)
  const getContentsWithoutPage = async (screenType: ScreenType) => {
    if (!workspaceId) return;

    const res = await api.get<ApiResponse<ContentsByPage>>(Endpoint.contents, {
      params: {
        workspaceId,
        // ! 등록된 콘텐츠가 1만개가 넘어가면 스케줄 추가 페이지에 페이지네이션 추가 해줘야 함
        page: 0,
        size: 10000,
        title: '',
        screenType,
      },
    });

    return res.data.content;
  };

  return {
    getContents,
    getContent,
    uploadContent,
    updateContent,
    deleteContent,
    getContentsWithoutPage,
  };
};

export default useContents;
