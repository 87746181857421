import { ICON } from 'constants/icons';
import useOutsideClick from 'hooks/common/useOutsideClick';
import React, { useEffect, useRef, useState } from 'react';

type Props = {
  disabled?: boolean;
  type: 'USE' | 'EXPOSURE' | 'POPUP';
  color?: 'BLUE' | 'GREEN' | 'YELLOW' | 'WHITE';
  value?: boolean;
  refreshKey?: string;
  onChangeOption: (option: boolean) => void;
};

type SwitchType = {
  [index: string]: { label: string; value: boolean }[];
};

type SwitchColorType = {
  [index: string]: { background: string; point: string; text: string };
};

const SWITCH: SwitchType = {
  USE: [
    { label: '미사용', value: false },
    { label: '사용', value: true },
  ],
  EXPOSURE: [
    { label: '노출', value: true },
    { label: '비노출', value: false },
  ],
  POPUP: [
    { label: '기본형', value: true },
    { label: '축약형', value: false },
  ],
};

const SwitchColor: SwitchColorType = {
  BLUE: {
    background: 'bg-state-blue_bg',
    point: 'border-[#7B8CC8] outline-[#7B8CC8]',
    text: 'text-[#7b8cc8]',
  },
  GREEN: {
    background: 'bg-state-green_bg',
    point: 'border-state-green outline-state-green',
    text: 'text-state-green',
  },
  YELLOW: {
    background: 'bg-state-yellow_bg',
    point: 'border-state-yellow outline-state-yellow',
    text: 'text-state-yellow',
  },
  WHITE: {
    background: 'bg-white border border-gray-ea',
    point: 'border-gray-99 outline-gray-99',
    text: 'text-gray-99',
  },
};

const Switch = ({ disabled, type, color = 'WHITE', onChangeOption, value, refreshKey }: Props) => {
  const [currentToggle, setCurrentToggle] = useState(SWITCH[type].find(type => type.value === value)?.label);
  const [toggleOpen, setToggleOpen] = useState(false);
  const toggleRef = useRef<HTMLDivElement>(null);

  const closeToggle = () => {
    setToggleOpen(false);
  };

  useOutsideClick({ elementRef: toggleRef, callback: closeToggle });

  const handleToggleOption = () => {
    if (disabled) return;
    setToggleOpen(!toggleOpen);
  };

  const handleClickOption = (option: { label: string; value: boolean }) => {
    if (disabled) return;

    setCurrentToggle(option.label);
    onChangeOption(option.value);

    setToggleOpen(!toggleOpen);
  };

  useEffect(() => {
    const currentLabel = SWITCH[type].find(type => type.value === value);
    if (currentLabel) {
      setCurrentToggle(currentLabel.label);
    }
  }, [refreshKey, value]);

  return (
    <div className='relative z-20' ref={toggleRef}>
      <div
        className={`w-[77px] h-8 rounded-full flex justify-between items-center 
        ${SwitchColor[color].background} text-gray-cc text-xs pl-3 pr-2  ${
          !disabled &&
          `hover:outline outline-1 cursor-pointer ${SwitchColor[color].point} ${
            !value && type === 'USE' && 'bg-white border border-gray-ea '
          }`
        }`}
        onClick={handleToggleOption}
      >
        {/* Badge Text */}
        <div
          className={`${disabled && 'text-gray-cc'} ${SwitchColor[color].text} ${
            type === 'USE' && !value && 'text-gray-99'
          }`}
        >
          {currentToggle}
        </div>
        <div>
          <img src={disabled ? ICON.ARROW : ICON.ARROW_DOWN_ACTIVE} />
        </div>
      </div>

      {toggleOpen && (
        <div
          className={`w-[77px] absolute top-0 left-0 rounded-2xl shadow-mdDrop ${SwitchColor[color].background} border ${SwitchColor[color].point} cursor-pointer`}
        >
          {SWITCH[type].map((option, optionIndex) => (
            <div id={option.label} key={optionIndex}>
              {optionIndex === 0 ? (
                <div className='flex items-center justify-start h-8 text-xs'>
                  <div
                    onClick={handleClickOption.bind(this, option)}
                    id={option.label}
                    className={`flex items-center justify-between w-full h-full pl-3 pr-2 ${SwitchColor[color].text}`}
                  >
                    {option.label}
                    <img id={option.label} src={ICON.ARROW_UP_ACTIVE} alt='arrow-up' />
                  </div>
                </div>
              ) : (
                <div
                  onClick={handleClickOption.bind(this, option)}
                  id={option.label}
                  className={`text-xs h-8 flex justify-start items-center pl-3 pr-2  ${SwitchColor[color].text}`}
                >
                  {option.label}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Switch;
