import { IMAGES } from 'constants/images';

const TenantDetailImage = ({ mainLogoUri }: { mainLogoUri: string }) => {
  let tenantSubLogoUrl = mainLogoUri ? mainLogoUri : IMAGES.THUMBNAIL_DEFAULT;

  return (
    <div style={{ width: '100%' }}>
      <img
        src={tenantSubLogoUrl}
        style={{
          width: '100%',
          height: '250px',
        }}
        alt='tenant img'
        onError={e => {
          const element = e.target;
          if (element instanceof HTMLImageElement) {
            element.src = IMAGES.THUMBNAIL_DEFAULT;
          }
        }}
      />
    </div>
  );
};
export default TenantDetailImage;
