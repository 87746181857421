import dayjs from 'dayjs';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import { useEffect, useState } from 'react';
import useAccountStore from 'stores/account';
import { AccountMap } from 'types/common/account';
import { RoutePath } from 'types/common/paths';
import { Workspace } from 'types/feature/workspace/workspace';
import WorkspaceServiceTypeBadge from './badge/WorkspaceServiceTypeBadge';
import WorkspaceTypeBadge from './badge/WorkspaceTypeBadge';

type Props = {
  workspace: Workspace;
};

const WorkspaceCard = ({ workspace }: Props) => {
  // hook
  const { navigateToReplacedPath } = useCustomNavigate();
  // state
  const [mapName, setMapName] = useState('');
  // store
  const accountStore = useAccountStore();

  const onClick = (id: string) => {
    // 메인페이지로 이동
    navigateToReplacedPath(RoutePath.dashboard, id);
  };

  // 계정 정보로부터 지도 이름 가져옴
  const fetchMapName = () => {
    try {
      if (accountStore.account) {
        const findMap = accountStore.account.maps.find((map: AccountMap) => map.id === workspace.map.id);

        if (findMap) {
          setMapName(findMap.name);
        }
      }
    } catch (error) {
      console.error('계정 정보를 불러올 수 없습니다.', error);
    }
  };

  useEffect(() => {
    fetchMapName();
  }, [workspace, accountStore.account]);

  return (
    <div
      className='w-full h-[214px] bg-primary-White px-10 py-9 rounded-xl flex flex-col justify-between shadow cursor-pointer border-2 border-white hover:bg-state-blue_bg hover:border-state-blue hover:border-2'
      onClick={onClick.bind(this, workspace.id)}
    >
      <div>
        {/* 상단 배지 */}
        <div className='flex items-start h-[24px] mb-2 gap-[6px]'>
          {/* 서비스 타입 */}
          <WorkspaceServiceTypeBadge serviceType={workspace.useType} />
          {/* 워크스페이스 타입 */}
          <WorkspaceTypeBadge type={workspace.type} />
        </div>

        {/* 워크스페이스 이름 */}
        <div className='break-all whitespace-pre-wrap font-h2 text-[18px] leading-[26px] line-clamp-2 tracking-default text-gray-44'>
          {workspace.name}
        </div>
      </div>

      <div className='flex flex-col gap-1'>
        {/* 지도 정보 */}
        {mapName && (
          <div className='flex items-center gap-2'>
            <span className='font-bold text-gray-99 text-body2'>Map.</span>
            <span className='break-all whitespace-pre-wrap text-gray-99 font-body text-body2 line-clamp-1'>
              {mapName}
            </span>
          </div>
        )}

        {/* 최종 수정일 */}
        <div className='flex items-center gap-2'>
          <span className='font-bold text-gray-99 text-body2'>Modified Date.</span>
          <span className='break-all whitespace-pre-wrap text-gray-99 font-body text-body2 line-clamp-1'>
            {dayjs(workspace.updatedDate).format('YYYY.MM.DD HH:mm')}
          </span>
        </div>
      </div>
    </div>
  );
};
export default WorkspaceCard;
