import { CustomField } from 'types/feature/poi/poi';

// 커스텀 필드 order
const CUSTOM_FIELDS_ORDER: string[] = [
  'businessHours',
  'phone',
  'owner',
  'address',
  'email',
  'classification',
  'product',
  'homePage',
  'snsYoutube',
  'snsInstagram',
  'snsFacebook',
  'snsLinkedIn',
  'snsBlog',
  'snsKakao',
];

const customField: { [key: string]: string } = {
  address: '주소',
  businessHours: '운영시간',
  classification: '분류',
  email: '이메일',
  homePage: '홈페이지',
  owner: '대표',
  phone: '전화번호',
  product: '품목',
  snsBlog: 'Blog',
  snsFacebook: 'Facebook',
  snsInstagram: 'Instagram',
  snsKakao: 'Kakao Ch.',
  snsLinkedIn: 'LinkedIn',
  snsYoutube: 'Youtube',
};

const TenantCustomField = ({ customFields }: { customFields: CustomField }) => {
  return (
    <div>
      {customFields &&
        CUSTOM_FIELDS_ORDER.map((fieldName: string, index: number) => {
          const content = customFields[fieldName];
          // content 가 빈값이면 표시 X
          if (content === '') return;

          // 홈페이지
          // if (fieldName === 'homePage') {
          //   if (isValidQr(content)) {
          //     return (
          //       <QrField
          //         customField={{
          //           title: customField[fieldName],
          //           content,
          //         }}
          //       />
          //     );
          //   }

          //   // TODO: url 검증 오류 시 표출 오류 데이터 노출 (추후 다른 처리 필요 없을시 리팩토링 할 것)
          //   return (
          //     <div key={index}>
          //       {/* 제목 */}
          //       <div>{/* {t(`popup.customField.${fieldName}`)} */}</div>
          //       {/* 내용 */}
          //       <div>
          //         <div>{content}</div>
          //       </div>
          //     </div>
          //   );
          // }

          // 그외 모든 데이터
          return (
            <div
              key={index}
              style={{
                display: 'flex',
                position: 'relative',
                // gap: '20px',
              }}
            >
              {/* 제목 */}
              <div
                className='flex w-[100px] text-gray-99 text-[14px] font-semibold'
                style={{
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {customField[fieldName]}
              </div>

              {/* 내용 */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  width: '100%',
                  borderBottom: '1px solid #ccc',
                }}
              >
                <div
                  className='text-gray-44 text-[14px] font-normal'
                  style={{
                    width: '100%',
                    height: '100%',
                    padding: '10px 0px',
                    whiteSpace: 'pre-wrap' /* \n을 개행으로 처리 */,
                    wordWrap: 'break-word' /* 긴 단어가 있을 경우 자동으로 줄바꿈 */,
                    wordBreak: 'break-all' /* 긴 단어를 강제로 줄바꿈 */,
                    overflowWrap: 'break-word' /* 현대 브라우저에서도 줄바꿈 지원 */,
                  }}
                >
                  {content}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default TenantCustomField;
