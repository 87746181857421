import { MultiLangs } from '../multi-lang/language';

export type MapStatus = {
  floor: string;
  ready: boolean;
  tenant: string;
};

export type MapObject = {
  id: string;
  status: MapStatus;
  version: string;
  temp1: string;
  temp2: string;
};

export type PointDetail = {
  hoursStart: string;
  hoursEnd: string;
  closedDays: string[];
};

export type Building = {
  id: string;
  name: MultiLangs;
  type: 'INDOOR' | 'OUTDOOR';
};

export type HoursType = 'DATE' | 'TEXT';

export type Point = {
  id: string;
  name: MultiLangs;
  description: string;
  building: Building;
  mapMinZoom: string;
  mapMaxZoom: string;
  logo: Logo | null;
  operation: {
    hoursType: HoursType;
    hoursText: MultiLangs;
    hoursUsed: boolean;
    hoursStart: string;
    hoursEnd: string;
    closedDays: string[];
    closedDaysUsed: boolean;
  };
};

export type BusinessHours = {
  hoursType: HoursType;
  hoursText: MultiLangs;
  hoursUsed: boolean;
  hoursStart: string;
  hoursEnd: string;
};

export type ClosedDays = {
  closedDaysUsed: boolean;
  closedDays: string[];
};

export type Logo = {
  name?: string;
  originalName?: string;
  delete?: boolean;
  uri?: string;
  size?: number;
  width?: number;
  height?: number;
};

export type WorkspaceType = 'RETAIL' | 'EXHIBITION' | 'OFFICE' | 'ETC';
export type WorkspaceUseType = 'SERVICE' | 'DRAFT' | 'ARCHIVE' | 'TEST';

export enum NaviSpeedOptionKo {
  LINEAR = '일정한 속도 유지',
  EASE_IN = '점점 빠르게',
}

export type NaviGationSpeedOption = 'LINEAR' | 'EASE_IN';

export type NavigationSetting = {
  lineThickness: number;
  navigationSpeed: number;
  navigationSpeedOption: NaviGationSpeedOption;
};

export type Workspace = {
  id: string;
  name: string;
  description: string;
  machineType: string;
  type: WorkspaceType;
  useType: WorkspaceUseType;
  points: Point[];
  createdDate: string;
  updatedDate: string;
  map: MapObject;
  mobileUsed: boolean;
  clientType: 'DID' | 'OLIVE_YOUNG';
  autoSync: boolean;
  legacy: boolean;
  lineThickness: number;
  navigationSpeed: number;
  navigationSpeedOption: NaviGationSpeedOption;
};
