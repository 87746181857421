import Border from 'components/common/border/Border';
import { BorderColor } from 'components/common/border/Border.type';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import AlertModal from 'components/common/modal/alert/AlertModal';
import ConfirmModal from 'components/common/modal/confirm/ConfirmModal';
import useContents from 'hooks/feature/content/useContents';
import { useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CONTENTS_BG } from 'styles/background';
import { RoutePath } from 'types/common/paths';
import { Content, ScreenTypeCode } from 'types/feature/content/contents';
import { PostContent } from 'types/feature/content/postContent';
import { replaceWorkspaceId } from 'utils/common/replaceId';
import ContentAddForm from '../add/form/ContentAddForm';

type Props = {
  content: Content;
};

const ScreensaverContentEditContainer = ({ content }: Props) => {
  const navigate = useNavigate();
  const { workspaceId, contentId } = useParams();
  const { updateContent, deleteContent } = useContents();
  const location = useLocation();

  const inputRef = useRef<HTMLInputElement>(null);

  const [editForm, setEditForm] = useState<PostContent>({
    workspaceId: '',
    title: content.title ?? '',
    type: 'KIOSK',
    fileType: content.fileType,
    screenType: content.screenType as ScreenTypeCode,
    file: {
      name: content.file.name,
      originalName: content.file.originalName,
      delete: false,
    },
  });
  const [openFailPopup, setOpenFailPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [openSaveConfirm, setOpenSaveConfirm] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  // 타이틀 비어있는지 검사
  const validateTitle = () => {
    if (!editForm.title) {
      setAlertMessage('타이틀을 입력해주세요.');
      setOpenFailPopup(true);

      setOpenSaveConfirm(false);
      setOpenDeleteConfirm(false);
      return false;
    }

    return true;
  };

  // 수정 클릭 시
  const clickUpdate = () => {
    if (validateTitle()) {
      setOpenSaveConfirm(true);
    }
  };

  // 타이틀 수정
  const handleUpdateTitle = async () => {
    setOpenSaveConfirm(false);

    if (inputRef.current && contentId) {
      const { value } = inputRef.current;

      await updateContent(contentId, value);
      // await fetchContent();

      moveToContentPage();
    }
  };

  // 취소 클릭 시
  const clickCancel = () => {
    moveToContentPage();
  };

  // 삭제 클릭 시
  const clickDelete = () => {
    setOpenDeleteConfirm(true);
  };

  const handleDelete = async () => {
    if (contentId) {
      await deleteContent(contentId);

      if (workspaceId) {
        navigate(replaceWorkspaceId(RoutePath.content, workspaceId), {
          state: {
            pageIndex: 0,
            screenType: location.state?.screenType || 'VERTICAL',
            searchWord: location.state?.searchWord || '',
          },
        });
      }
    }
  };

  const moveToContentPage = () => {
    if (workspaceId) {
      navigate(replaceWorkspaceId(RoutePath.content, workspaceId), {
        state: {
          pageIndex: location.state?.pageIndex || 0,
          screenType: location.state?.screenType || 'VERTICAL',
          searchWord: location.state?.searchWord || '',
        },
      });
    }
  };

  return (
    <>
      <div className={`${CONTENTS_BG} p-10 flex flex-col items-center gap-6`}>
        <ContentAddForm isEdit addForm={editForm} setAddForm={setEditForm} content={content} inputRef={inputRef} />
        <Border borderColor={BorderColor.gray} />
        <div className='flex items-center justify-between w-full'>
          <div className='flex gap-[10px] pl-[40%]'>
            <Button size={120} text='목록으로' color={ButtonColor.secondary} onClick={clickCancel} />
            <Button size={120} text='수정' color={ButtonColor.primary} onClick={clickUpdate} />
          </div>

          <Button size={120} text='삭제' color={ButtonColor.secondary} onClick={clickDelete} />
        </div>
      </div>

      {openSaveConfirm && (
        <ConfirmModal
          message='콘텐츠 타이틀을 수정하시겠습니까?'
          onClickCancel={() => setOpenSaveConfirm(false)}
          onClickOk={handleUpdateTitle}
          disabled={!openSaveConfirm}
        />
      )}

      {openDeleteConfirm && (
        <ConfirmModal
          message='콘텐츠를 삭제하시겠습니까?'
          onClickCancel={() => setOpenDeleteConfirm(false)}
          onClickOk={handleDelete}
          disabled={!openDeleteConfirm}
        />
      )}

      {openFailPopup && (
        <AlertModal width='min-w-[380px]' closeModal={() => setOpenFailPopup(false)} message={alertMessage} />
      )}
    </>
  );
};
export default ScreensaverContentEditContainer;
