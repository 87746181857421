import { WorkspaceUseType } from 'types/feature/workspace/workspace';
import { capitalizeFirstLetter } from 'utils/common/capitalizeFirstLetter';

const WorkspaceServiceTypeBadge = ({ serviceType }: { serviceType: WorkspaceUseType }) => {
  const switchBadgeColors = () => {
    switch (serviceType) {
      case 'SERVICE':
        return { background: '#EBFAF4', text: '#43CA92' };
      case 'DRAFT':
        return { background: '#F5F5F5', text: '#BFBFBF' };
      case 'ARCHIVE':
        return { background: '#FFF3D4', text: '#EAB222' };
      case 'TEST':
        return { background: '#F5F5F5', text: '#BFBFBF' };
      default:
        return { background: '#FFFFFF', text: '#000000' };
    }
  };
  return (
    <div
      style={{
        background: switchBadgeColors().background,
      }}
      className='h-[24px] px-[6px] flex justify-center items-center rounded'
    >
      <span
        style={{
          color: switchBadgeColors().text,
        }}
        className='text-[12px] font-medium leading-3'
      >
        {capitalizeFirstLetter(serviceType)}
      </span>
    </div>
  );
};
export default WorkspaceServiceTypeBadge;
