import DotSpan from 'components/common/dot/DotSpan';

type Props = { name: string; floor: string; building: string };

const TenantPopupTitle = ({ name, floor, building }: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          padding: '12px 30px 3px 30px',
          color: '#444',
          fontSize: '24px',
          fontWeight: '700',
        }}
      >
        {name}
      </div>
      <div>
        <div className='flex items-center h-[30px]'>
          {/* 층 */}
          {floor.length > 0 && <div className='text-gray-44 text-[12px] font-bold'>{floor}</div>}

          {/* 부스 번호 - 전시일 경우 노출 */}
          {/* <div className='flex items-center'>
            <DotSpan /> <div className='text-gray-44 text-[12px] font-bold'>부스번호</div>
          </div> */}

          {/* 포인트 */}
          {building.length > 0 && (
            <div className='flex items-center text-[12px] text-gray-99'>
              <DotSpan />
              <div>{building}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default TenantPopupTitle;
