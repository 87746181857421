import { TextType } from 'components/common/title/Title';
import useWorkspaceStatus from 'hooks/common/useWorkspaceStatus';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { Fragment, useEffect, useState } from 'react';
import { Workspace } from 'types/feature/workspace/workspace';
import WorkspaceCard from './item/WorkspaceCard';

const WorkspaceListContainer = () => {
  // hook
  const { getWorkspaces } = useWorkspace();
  const { handleWorkspaceStatus } = useWorkspaceStatus();
  // state
  const [workspaces, setWorkspaces] = useState<Workspace[]>();
  const [viewAll, setViewAll] = useState<boolean>(false);

  /**
   * 워크스페이스 목록을 조회한다.
   */
  const fetchWorkspaces = async (serviceOnly: boolean) => {
    const result = await getWorkspaces();
    if (!result) return;
    const filteredWorkspace = serviceOnly ? result.filter(workspace => workspace.useType === 'SERVICE') : result;
    setWorkspaces(filteredWorkspace);
  };

  /**
   * view all 여부에 따라 워크스페이스 목록을 필터링한다.
   */
  useEffect(() => {
    fetchWorkspaces(!viewAll);
  }, [viewAll]);

  useEffect(() => {
    if (workspaces) {
      // 워크스페이스 fetch 이후 workspace status 세팅
      handleWorkspaceStatus(workspaces);
    }
  }, [workspaces]);

  /**
   * view all 을 클릭한다.
   */
  const onClickViewAll = () => {
    setViewAll(prev => !prev);
  };

  return (
    <div className='flex items-center justify-center w-full h-full py-10'>
      <section className='flex flex-col gap-[12px] w-[1400px] h-full'>
        {/* 서비스별 필터 */}
        <div className='flex items-center justify-end h-6'>
          <div className='flex items-center gap-2'>
            {/* 진짜 체크박스 */}
            <input type='checkbox' className='hidden peer' checked={viewAll} readOnly />
            {/* 스타일링을 위한 체크박스 */}
            <div
              onClick={onClickViewAll}
              className='cursor-pointer w-5 h-5 transition-all bg-gray-200 border-2 border-gray-300 rounded-sm peer-checked:bg-[#92D5DE] peer-checked:border-[#92D5DE]'
            >
              {/* 체크표시 */}
              {viewAll && (
                <svg
                  className='w-4 h-4'
                  fill='none'
                  stroke='#272A36'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2.5' d='M5 13l4 4L19 7' />
                </svg>
              )}
            </div>
            {/* 체크박스 라벨 */}
            <label onClick={onClickViewAll} htmlFor='checkbox' className={`cursor-pointer ${TextType.h4_bold}`}>
              View All
            </label>
          </div>
        </div>

        {/* 워크스페이스 목록 */}
        <div className='grid w-full h-full grid-cols-4 gap-5 large:grid-cols-4 medium:grid-cols-2 small:grid-cols-2'>
          {workspaces?.map(workspace => (
            <Fragment key={workspace.id}>
              <WorkspaceCard workspace={workspace} />
            </Fragment>
          ))}
        </div>
      </section>
    </div>
  );
};
export default WorkspaceListContainer;
