import { AxiosError } from 'axios';
import FileDropZone from 'components/common/file/drop-zone/FileDropZone';
import FilePreview from 'components/common/file/preview/FilePreview';
import FormControl from 'components/common/form-control/FormControl';
import Guide from 'components/common/guide/Guide';
import { FILE_UPLOAD_NOTICE } from 'components/common/guide/data/fileUpload';
import Input from 'components/common/input/Input';
import AlertModal from 'components/common/modal/alert/AlertModal';
import SelectBox, { OptionType } from 'components/common/select/SelectBox';
import {
  CONTENT_IMAGE_GUIDES,
  CONTENT_VIDEO_GUIDES,
} from 'components/feature/screensaver/contents/add/guide/screensaver';
import { SCREEN_TYPE } from 'data/screenType';
import { FILE_TYPE_OPTIONS, SCREEN_TYPE_OPTIONS } from 'data/select-box/options';
import useFileUpload from 'hooks/common/useFileUpload';
import { ChangeEvent, RefObject, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FILE_TYPES, FileType } from 'types/common/file/file';
import { FileUploadType } from 'types/common/file/fileUpload';
import { Content, ScreenTypeCode } from 'types/feature/content/contents';
import { PostContent } from 'types/feature/content/postContent';
import { returnFileAccept } from 'utils/file/extension/returnFileAccept';
import { returnFileMaxSize } from 'utils/file/size/returnFileSize';

type Props = {
  addForm: PostContent;
  setAddForm: React.Dispatch<React.SetStateAction<PostContent>>;
  isEdit?: boolean;
  content?: Content;
  inputRef?: RefObject<HTMLInputElement>;
};

const ContentAddForm = ({ addForm, setAddForm, isEdit, content, inputRef }: Props) => {
  const [fileLoading, setFileLoading] = useState(false);
  const [failMessage, setFailMessage] = useState('');
  const [uploadFail, setUploadFail] = useState(false);
  const [ableToUpload, setAbleToUpload] = useState(false);
  const [fileUri, setFileUri] = useState('');

  const fileRef = useRef<HTMLInputElement>(null);

  const { uploadFile } = useFileUpload();
  const { workspaceId } = useParams();

  // 타이틀
  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setAddForm((prev: PostContent) => ({ ...prev, title: value }));
  };

  // 구분
  const handleScreenTypeSelect = (option: OptionType) => {
    const screenType = option.value as ScreenTypeCode;
    setAddForm((prev: PostContent) => ({ ...prev, screenType }));
  };

  // 파일 등록 옵션 선택
  const handleFileSelectChange = (option: OptionType) => {
    const { value } = option;
    handleResetFile();
    if (workspaceId) {
      setAddForm((prev: PostContent) => ({
        ...prev,
        workspaceId,
        fileType: value as FileType,
      }));
    }
  };

  // 삭제
  const handleDeleteFile = () => {
    setAddForm((prev: PostContent) => ({
      ...prev,
      file: {
        name: '',
        originalName: '',
        delete: false,
      },
    }));
  };

  // 리셋
  const handleResetFile = () => {
    handleDeleteFile();
    setFileUri('');
    if (fileRef.current) {
      fileRef.current.value = '';
    }
  };

  // 파일 업로드 실패 시
  const openFileErrorAlert = (message: string) => {
    setFailMessage(message);
    setUploadFail(true);
    setFileLoading(false);
  };

  // temp에 파일 업로드
  const handleChangeFile = async (file: File) => {
    if (file && addForm.fileType) {
      await uploadContentFile(file);
    }
  };

  // 콘텐츠 파일 업로드
  const uploadContentFile = async (file: File) => {
    try {
      if (file && addForm.fileType) {
        setFileLoading(true);
        const result = await uploadFile(
          file,
          addForm.fileType === FileType.IMAGE ? FileUploadType.CONTENT_IMAGE : FileUploadType.CONTENT_VIDEO,
        );

        if (result) {
          const response = result.data;

          setFileUri(response.fileUri);
          setFileLoading(false);
          setAddForm((prev: PostContent) => ({
            ...prev,
            file: {
              name: response.fileName,
              originalName: file.name,
              delete: false,
            },
          }));
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          openFileErrorAlert('파일 업로드 중 문제가 발생했습니다.');
        }
      }
    }
  };

  // file type -> file upload type
  const convertContentUploadType = () => {
    if (addForm.fileType === FileType.IMAGE) {
      return FileUploadType.CONTENT_IMAGE;
    }

    if (addForm.fileType === FileType.VIDEO) {
      return FileUploadType.CONTENT_VIDEO;
    }

    return FileUploadType.CONTENT_IMAGE;
  };

  useEffect(() => {
    if (addForm.fileType && addForm.screenType) {
      setAbleToUpload(true);
    }
  }, [addForm]);

  return (
    <>
      <div className='flex flex-col items-start w-full gap-3'>
        <FormControl
          name='타이틀'
          required
          controlWidth='w-[360px]'
          labelSize={200}
          control={
            <Input
              useWordLimit
              limit={20}
              placeholder='제목을 입력해주세요'
              name='title'
              onChange={handleTitleChange}
              value={addForm.title || ''}
              inputRef={inputRef}
            />
          }
        />

        <FormControl
          name='구분'
          required
          labelSize={200}
          control={
            <SelectBox
              disabled={isEdit}
              placeholder={SCREEN_TYPE[addForm.screenType ?? '선택']}
              options={[...SCREEN_TYPE_OPTIONS]}
              onChangeSelect={handleScreenTypeSelect}
            />
          }
        />

        <FormControl
          name='파일 등록'
          required
          labelSize={200}
          control={
            <div className='flex flex-col gap-3'>
              <div className='flex gap-2.5'>
                <SelectBox
                  disabled={isEdit}
                  placeholder={!isEdit ? '선택' : FILE_TYPES[addForm.fileType ?? '선택']}
                  options={[...FILE_TYPE_OPTIONS]}
                  onChangeSelect={handleFileSelectChange}
                />
              </div>

              {ableToUpload && (
                <>
                  {isEdit ? (
                    // 미리보기 영역
                    <FilePreview
                      fileType={content?.fileType ?? FileType.IMAGE}
                      fileUri={content?.file.uri ?? ''}
                      fileName={content?.file.originalName}
                    />
                  ) : (
                    // 파일 업로드 영역
                    <FileDropZone
                      maxSize={returnFileMaxSize(convertContentUploadType())}
                      uploadFile={handleChangeFile}
                      fileUri={fileUri}
                      fileType={addForm?.fileType ?? FileType.IMAGE}
                      file={addForm && addForm.file}
                      deleteFile={handleDeleteFile}
                      accept={returnFileAccept(convertContentUploadType())}
                      openFileErrorAlert={openFileErrorAlert}
                      loading={fileLoading}
                    />
                  )}

                  {/* 가이드 */}
                  {!isEdit && (
                    <Guide
                      notice={FILE_UPLOAD_NOTICE}
                      guides={addForm?.fileType === FileType.IMAGE ? CONTENT_IMAGE_GUIDES : CONTENT_VIDEO_GUIDES}
                      isList
                    />
                  )}
                </>
              )}
            </div>
          }
        />
      </div>

      {uploadFail && <AlertModal closeModal={() => setUploadFail(false)} message={failMessage} />}
    </>
  );
};

export default ContentAddForm;
