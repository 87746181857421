type Props = {
  keyword: string;
  type: 'list' | 'item';
};

const KeywordItem = ({ keyword, type }: Props) => {
  return (
    <div className='flex h-[24px] px-[10px] justify-center items-center rounded-[2px] bg-state-blue_bg'>
      <span className='overflow-hidden text-state-blue text-ellipsis text-[14px]'>{keyword.slice(0, 16)}</span>
    </div>
  );
};
export default KeywordItem;
