import useContents from 'hooks/feature/content/useContents';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';
import { ContentsByPage, ScreenType } from 'types/feature/content/contents';
import { clearHistoryStates } from 'utils/common/clearHistoryStates';
import { replaceWorkspaceId } from 'utils/common/replaceId';
import ContentsTable from './body/ContentsTable';
import ContentsHeader from './header/ContentsHeader';

const ScreensaverContentsContainer = () => {
  // hook
  const { getContents } = useContents();
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // state
  const [screenType, setScreenType] = useState<ScreenType>(location.state?.screenType || 'VERTICAL');
  const [searchWord, setSearchWord] = useState<string>(location.state?.searchWord || '');
  const [currentPage, setCurrentPage] = useState(location.state?.pageIndex || 0);
  const [totalContents, setTotalContents] = useState<ContentsByPage>();

  // 스크린 타입 검색
  const onChangeScreenType = (type: ScreenType) => {
    setScreenType(type);
    setCurrentPage(0);
  };

  // 타이틀 검색
  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const word = e.target.value;
    setSearchWord(word);
  };

  // 페이지 클릭시 동작
  const onClickPage = async (pageIndex: number) => {
    if (totalContents) {
      if (totalContents?.content.length > 0) {
        setCurrentPage(pageIndex);
        await fetchContents(pageIndex, searchWord, screenType);
      }
    }
  };

  const fetchContents = async (pageNum: number, title: string, screenType: ScreenType) => {
    const result = await getContents(pageNum, title, screenType);

    if (result) {
      setTotalContents(result);
    }
  };

  // 콘텐츠 등록 페이지로 이동
  const clickAdd = () => {
    if (workspaceId) {
      navigate(replaceWorkspaceId(RoutePath.contentAdd, workspaceId), {
        state: {
          pageIndex: currentPage,
          screenType,
          searchWord,
        },
      });
    }
  };

  // 검색 초기화
  const resetFilter = async () => {
    clearHistoryStates();
    setSearchWord('');
    setScreenType('VERTICAL');
    setCurrentPage(0);
    await fetchContents(0, '', 'VERTICAL');
  };

  // 페이지 리셋
  const resetPage = () => {
    setCurrentPage(0);
  };

  useEffect(() => {
    fetchContents(currentPage, searchWord, screenType);
  }, []);

  if (!totalContents) {
    return <> </>;
  }

  return (
    <>
      <div className='flex flex-col gap-6'>
        <ContentsHeader
          handleChangeTitle={handleChangeTitle}
          searchWord={searchWord}
          fetchContents={fetchContents}
          resetFilter={resetFilter}
          clickAdd={clickAdd}
          resetPage={resetPage}
          screenType={screenType}
          onChangeFilter={onChangeScreenType}
        />
        <ContentsTable
          contents={totalContents?.content}
          currentPage={currentPage}
          totalElements={totalContents && totalContents.totalElements}
          refreshContents={fetchContents}
          onChangePage={onClickPage}
          resetPage={resetPage}
          screenType={screenType}
          searchWord={searchWord}
        />
      </div>
    </>
  );
};
export default ScreensaverContentsContainer;
