/* eslint-disable react-hooks/exhaustive-deps */
import { AxiosError } from 'axios';
import LoadingMap from 'components/common/map/loading/LoadingMap';
import AlertModal from 'components/common/modal/alert/AlertModal';
import ToastPopup from 'components/common/toast/ToastPopup';
import TenantEditSpace from 'components/feature/tenant/edit/TenantEditSpace';
import TenantInfoControllers from 'components/feature/tenant/info/controller/TenantInfoControllers';
import TenantMapContainer from 'components/feature/tenant/info/map/TenantMapContainer';
import useModal from 'hooks/common/useModal';
import useFloor from 'hooks/feature/floor/useFloor';
import useLanguage from 'hooks/feature/language/useLanguage';
import useTenant from 'hooks/feature/tenant/useTenant';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useMapStore from 'stores/map';
import usePoiContentStore from 'stores/poiContent';
import useTenantToastStore from 'stores/tenantToast';
import { CONTENTS_BG } from 'styles/background';
import { TABLE_PADDING } from 'styles/padding';
import { Floor } from 'types/feature/floor/floor';
import { Language } from 'types/feature/multi-lang/language';
import { Tenant } from 'types/feature/tenant/tenant';
import { Point, Workspace } from 'types/feature/workspace/workspace';
import { convertLangCodeForMap } from 'utils/language/convertLangCodeForMap';
import MapDraw from 'utils/map/mapDraw';

export type MainLang = {
  defaultLang: string;
  mainLang: string;
};

const TenantInfoContainer = () => {
  // 지도
  const [workspace, setWorkspace] = useState<Workspace>();

  // 포인트
  const [points, setPoints] = useState<Point[]>([]);
  const [selectedPoint, setSelectedPoint] = useState<Point | null>();

  const [floors, setFloors] = useState<Floor[]>([]);
  const [selectedFloor, setSelectedFloor] = useState<Floor>();

  // 언어
  const [languages, setLanguages] = useState<Language[]>([]);
  const [exposureLanguages, setExposureLanguages] = useState<Language[]>([]);
  const [mainLang, setMainLang] = useState<MainLang>({
    defaultLang: '',
    mainLang: '',
  });
  const [selectedLang, setSelectedLang] = useState<string>('');

  // 선택된 테넌트
  const [tenant, setTenant] = useState<Tenant | null>(null);

  // 지도에서 클릭한 poi
  const [clickedPoiId, setClickedPoiId] = useState('');

  // ref
  const toastTimerRef = useRef<NodeJS.Timer | null>(null);

  // store
  const mapStore = useMapStore();
  const poiContentStore = usePoiContentStore();
  const tenantToastStore = useTenantToastStore();

  // hook
  const { workspaceId } = useParams();
  const { getWorkspace } = useWorkspace();
  const { openAlert, openModalPopup, closeModalPopup } = useModal();
  const { getFloors } = useFloor();
  const { getLanguages } = useLanguage();
  const { getTenant } = useTenant();

  // 토스트 팝업용 타이머 생성
  useEffect(() => {
    tenantToastStore.setToastTimerRef(toastTimerRef);
  }, []);

  // 워크스페이스 fetch
  const fetchWorkspace = async () => {
    if (workspaceId) {
      const result = await getWorkspace(workspaceId);
      if (result) {
        setWorkspace(result);
        setPoints(result.points);

        // OUTDOOR 있다면 포인트를 outdoor로 먼저 선택
        const findOutdoor: Point | undefined = result.points.find(point => point.building.type === 'OUTDOOR');
        if (findOutdoor) {
          setSelectedPoint(findOutdoor);
        } else {
          // OUTDOOR 가 없다면 첫번째 포인트 선택
          setSelectedPoint(result.points[0]);
        }
      }
    }
  };

  const setMainFloor = (floors: Floor[]) => {
    const mainFloor = floors.find(floor => floor.main);
    if (mainFloor) {
      setSelectedFloor(mainFloor);

      return mainFloor;
    }
  };

  // 층 리스트 fetch
  const fetchFloors = async (pointId: string) => {
    try {
      const result = await getFloors(pointId);

      setFloors(result);
      setMainFloor(result);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          openModalPopup('ALERT');
        }
      }
    }
  };

  // 지도 언어 get
  const getMapLangs = (displayLangs: Language[]) => {
    if (MapDraw.map) {
      const mapLanguages = MapDraw.mapData.dataLanguage.getLanguage();

      if (mapLanguages) {
        const mapDefaultLanguage = mapLanguages.find((lang: any) => lang.defaultYn);
        setMainLang((prev: MainLang) => ({
          ...prev,
          defaultLang: mapDefaultLanguage.lang.toUpperCase(),
        }));

        const availableSelectLanguageList = displayLangs.filter(lang =>
          mapLanguages.some((mapLang: any) => convertLangCodeForMap(lang.code.toLowerCase()) === mapLang.lang),
        );

        setLanguages(availableSelectLanguageList);
        setSelectedLang(availableSelectLanguageList[0].code);
      }
    }
  };

  // 언어 리스트 fetch
  const fetchLangs = async () => {
    try {
      const languages = await getLanguages();

      if (languages) {
        const displayLangs = languages.filter(lang => lang.display);
        const mainLanguage = languages.find(lang => lang.main);

        if (mainLanguage) {
          setMainLang((prev: MainLang) => ({
            ...prev,
            mainLang: mainLanguage.code,
          }));
        }
        setExposureLanguages(displayLangs);

        getMapLangs(displayLangs);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          openModalPopup('ALERT');
        }
      }
    }
  };

  // 테넌트 조회
  const fetchTenantByPoiContentId = async (contentId: string) => {
    if (selectedPoint) {
      const tenant = await getTenant(contentId, selectedPoint.id);

      if (tenant) {
        setTenant(tenant);
      }
    }
  };

  // 포인트 선택
  const handleSelectedPoint = async (point: Point) => {
    setSelectedPoint(point);

    const result = await getFloors(point.id);

    if (result) {
      setFloors(result);
      setMainFloor(result);
    }
  };

  // 층 선택
  const handleSelectedFloor = (floor: Floor) => {
    setSelectedFloor(floor);
  };

  // 1. workspace
  useEffect(() => {
    fetchWorkspace();
  }, []);

  // 1. 언어
  useEffect(() => {
    if (!mapStore.isMapLoading) {
      fetchLangs();
    }
  }, [mapStore.isMapLoading, workspaceId]);

  // 2. 층
  useEffect(() => {
    if (selectedPoint) {
      fetchFloors(selectedPoint.id);
    }
  }, [selectedPoint]);

  useEffect(() => {
    if (poiContentStore.currentContentId) {
      fetchTenantByPoiContentId(poiContentStore.currentContentId);
    }
  }, [poiContentStore.currentContentId]);

  useEffect(() => {
    return () => {
      poiContentStore.clearCurrentContentId();
    };
  }, []);

  if (!selectedPoint || !selectedFloor || !workspace || !points) {
    return <> </>;
  }

  return (
    <>
      <div className='w-[1400px] h-[698px] flex gap-6'>
        <div className={`${CONTENTS_BG} ${TABLE_PADDING} w-[896px] h-[698px] flex flex-col gap-5 relative`}>
          {/* 언어, 빌딩, 층 필터 */}
          <TenantInfoControllers
            pointList={points}
            floorList={floors}
            languageList={languages}
            handleSelectedFloor={handleSelectedFloor}
            handleSelectedPoint={handleSelectedPoint}
            mainLanguage={mainLang}
            selectedFloor={selectedFloor}
            selectedPoint={selectedPoint}
            selectedLang={selectedLang}
            setSelectedLang={setSelectedLang}
            setClickedPoiId={setClickedPoiId}
          />

          {/* 지도 */}
          <TenantMapContainer
            workspace={workspace}
            selectedPoint={selectedPoint}
            selectedFloor={selectedFloor}
            setClickedPoiId={setClickedPoiId}
          />

          {mapStore.isMapLoading && <LoadingMap />}
        </div>

        {/* 테넌트 정보 */}
        <TenantEditSpace
          point={selectedPoint}
          clickedPoiId={clickedPoiId}
          selectedLang={selectedLang}
          selectedFloor={selectedFloor}
        />
      </div>

      {/* 오류 알림창 */}
      {openAlert && <AlertModal message='오류가 발생했습니다.' closeModal={() => closeModalPopup('ALERT')} />}

      {/* 토스트 팝업 */}
      {tenantToastStore.isToastOpen && (
        <ToastPopup
          message={tenantToastStore.toastMessage}
          closeToastAfterSeconds={() => {
            tenantToastStore.closeToastAfterSeconds(() => {
              tenantToastStore.setIsToastOpen(false);
            });
          }}
        />
      )}
    </>
  );
};

export default TenantInfoContainer;
