import Border from 'components/common/border/Border';
import Title, { TextType } from 'components/common/title/Title';
import usePoint from 'hooks/feature/point/usePoint';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';
import { Point } from 'types/feature/workspace/workspace';
import { replacePointId, replaceWorkspaceId } from 'utils/common/replaceId';
import BusinessHour from './business-hour/BusinessHour';
import Holiday from './holiday/Holiday';
import MapInfo from './map/MapInfo';
import NaviSetting from './navi/NaviSetting';
import PointsTab from './points-tab/PointsTab';

const BasicSetting = () => {
  const [points, setPoints] = useState<Point[]>();
  const [selectedPoint, setSelectedPoint] = useState<Point>();

  const { getMyPoints } = usePoint();
  const navigate = useNavigate();
  const { workspaceId, pointId } = useParams();

  const fetchPoints = async () => {
    const points = await getMyPoints();
    if (points) {
      setPoints(points);

      const selectedPoint = points.find(point => point.id === pointId);

      if (selectedPoint) {
        setSelectedPoint(selectedPoint);
      } else {
        setSelectedPoint(points[0]);
      }

      return points;
    }
  };

  // point 선택 시
  const onSelectPoint = (point: Point) => {
    setSelectedPoint(point);

    if (workspaceId) {
      navigate(replacePointId(replaceWorkspaceId(RoutePath.pointBasicSetting, workspaceId), point.id));
    }
  };

  useEffect(() => {
    fetchPoints();
  }, []);

  if (!points || !selectedPoint) {
    return <> </>;
  }

  return (
    <div className='flex flex-col gap-10 pt-5'>
      {/* 지도 정보 */}
      <section className='flex flex-col gap-5'>
        <Title text='지도 정보' textType={TextType.h2} />
        <div className='flex flex-col gap-6 p-10 bg-white border border-gray-ea'>
          <MapInfo />
        </div>
      </section>

      {/* 건물 관리 */}
      <section className='flex flex-col gap-5'>
        <Title text='건물 관리' textType={TextType.h2} />
        <div>
          {/* 포인트 선택 */}
          {points.length > 1 && (
            <PointsTab points={points} handleClickPoint={onSelectPoint} selectedPoint={selectedPoint} />
          )}
          <div className='flex flex-col p-10 bg-white border gap-9 border-gray-ea'>
            {/* 운영시간 */}
            <BusinessHour point={selectedPoint} fetchPoints={fetchPoints} handlePoint={onSelectPoint} />
            <Border />
            {/* 휴무일 */}
            <Holiday point={selectedPoint} fetchPoints={fetchPoints} handlePoint={onSelectPoint} />
          </div>
        </div>
      </section>

      {/* 모의보행 설정 */}
      <NaviSetting />
    </div>
  );
};

export default BasicSetting;
